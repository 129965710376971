import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { linkResolver } from 'utils/link-resolver'
import { FaqPageTpl, prepareFaqGroupsContent } from 'templates/faq'

const FaqPage = ({ data }) => {
	const content = prepareFaqGroupsContent(data.prismicFaqPage.data)

	return <FaqPageTpl {...content} />
}

export const query = graphql`
	query FaqPageQuery {
		prismicFaqPage {
			_previewable
			data {
				heading
				categories {
					category {
						...PrismicFaqCategoryFragment
					}
				}
			}
		}
	}
	fragment PrismicFaqCategoryFragment on PrismicLinkType {
		document {
			... on PrismicFaqCategory {
				id
				data {
					category_heading
					questions {
						answer: answer_rich {
							richText
						}
						question
					}
				}
			}
		}
	}
`

export default withPrismicPreview(FaqPage, [
	{
		repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
		linkResolver,
	},
])
